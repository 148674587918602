<template>
  <div>
    <div ref="navBar">
      <UserAdministrationNavBar :isCreateUser="true" @on-back="handleBack"/>
    </div>

    <b-badge class="w-100" variant="secondary" show size="sm"
      >Clone User</b-badge
    >

  <b-container class="mt-2 white-font" fluid>
      <b-row class="mx-2">
        <b-col cols="2">
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Username* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.username"
                  placeholder="Enter Username"
                  @input="validateInput('username')"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <template v-if="errors.username && errors.username.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.username }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Email* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.email"
                  placeholder="Enter Email"
                  @input="validateInput('email')"
                ></b-form-input> </b-input-group
            ></b-col>

            <template v-if="errors.email && errors.email.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.email }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">First Name* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.firstName"
                  placeholder="Enter First Name"
                  @input="validateInput('firstName')"
                ></b-form-input> </b-input-group
            ></b-col>

            <template v-if="errors.firstName && errors.firstName.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.firstName }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Last Name* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.lastName"
                  placeholder="Enter Last Name"
                  @input="validateInput('lastName')"
                ></b-form-input> </b-input-group
            ></b-col>

            <template v-if="errors.lastName && errors.lastName.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.lastName }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Mobile Number*</b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                 type="number"
                  v-model="userInfo.mobileNumber"
                  placeholder="Enter Mobile Number"
                  @input="validateInput('mobileNumber')"
                  class="no-spinners"
                ></b-form-input> </b-input-group
            ></b-col>
            <template
              v-if="errors.mobileNumber && errors.mobileNumber.length > 0"
            >
              <b-col cols="12">
                <span class="text-danger">{{ errors.mobileNumber }}</span>
              </b-col>
            </template>
          </b-row>
         <b-row class="my-4" v-if="isMasterAdmin">
            <b-col cols="12"
              >
            
              <b-button size="sm" block @click="userWorklistSetting(0)"  title="Add worklist settings"
                >Worklist Settings</b-button
              >
           
              </b-col
            >
          </b-row>
        </b-col>
        <b-col cols="10"
          ><b-row class="mt-2">
            <b-table
              class="white-font"
              :fields="fields"
              :items="items"
              :small="true"
            >
              <template #cell(actions)="data">
                <b-button v-if="data.item.saved" size="sm" title="Delete row" @click="deleteRow(data.item)"
                  ><b-icon
                    icon="trash-fill"
                  ></b-icon
                ></b-button>
                <template v-else>
                  <b-button size="sm" class="" title="Reset selection" @click="onReset(data.item)"
                    ><b-icon
                      icon="arrow-clockwise"
                    ></b-icon
                  ></b-button>
                </template>
              </template>
              <template #cell(customer)="data" v-if="isSuperAdmin">
                <b-dropdown
                  :text="
                    selectedCustomers[data.item.id]
                      ? selectedCustomers[data.item.id] + ' '
                      : 'Select customer'
                  "
                  size="sm"
                  block
                  class="dropdown user-administration-dropdown"
                >
                  <b-dropdown-item
                    href="#"
                    v-for="customer in filteredCustomers"
                    :key="customer.id"
                    :disabled="Object.values(selectedCustomers).includes(customer.name)"
                    @click="onCustomerSelect(data.item, customer)"
                    >{{ customer.name }}</b-dropdown-item
                  >
                </b-dropdown>
              </template>
              <template #cell(tenants)="data">
                <b-dropdown
                  id="dropdown-form"
                  ref="dropdown"
                  class="dropdown user-administration-dropdown"
                  :text="
                    selectedTenants[data.item.id] &&
                    selectedTenants[data.item.id].length
                      ? selectedTenants[data.item.id].length +
                        ' Tenant Selected'
                      : 'Select Tenant(s)'
                  "
                  size="sm"
                  block
                >
                  <b-dropdown-form class="w-100">
                    <b-form-checkbox-group
                      v-model="selectedTenants[data.item.id]"
                      :options="tenantsOfCustomers[data.item.id]"
                      stacked
                     @change="onTenantSelect(data.item)"
                    ></b-form-checkbox-group>
                  </b-dropdown-form>
                </b-dropdown>
              </template>
              <template #cell(permissions)="data">
              <b-dropdown
                  id="dropdown-form"
                  ref="dropdown"
                  class="dropdown user-administration-dropdown"
                  :text="totalSelectedPermisions(data.item.id)>0?totalSelectedPermisions(data.item.id)+' Permission(s) selected':'Select Permission(s)'
                  "
                  size="sm"
                  block
                >
                   <b-form-checkbox-group v-model="permissionGroupSelection[data.item.id]" stacked class="px-3">
                      <div v-for="(group, index) in allGroupedPermissions" :key="index">
                        <b-form-checkbox :value="group.name" @change="onPermisisonGroupSelection($event, data.item.id, group)">
                          {{ group.name }}
                        </b-form-checkbox>
                        <b-form-checkbox-group v-model="selectedPermissions[data.item.id][group.name]" stacked class="pl-4" @change="onPermissionSelection($event,data.item.id,group)">
                          <b-form-checkbox v-for="(permission, index) in group.permissions" :key="index" :value="permission.value">
                             <p :title="permission.description" class="my-0">{{ permission.text }}</p> 
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </div>
                </b-form-checkbox-group>
              
                </b-dropdown> </template
            >
                 <template #cell(WorklistSetting)="data">
              <b-button size="sm" block @click="userWorklistSetting(data.item)"  title="Add worklist settings"
                >Worklist Settings</b-button
              >
            </template>
            </b-table>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-button v-if="isSuperAdmin"
                class="mt-3"
                @click="addRow(items[items.length - 1])"
                :loading="isLoading"
                title="Add new row"
                >Add New Row</b-button
              >
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button
                class="mt-3"
                @click="cloneUser"
               :disabled="isLoading || !checkUserInfoFilled || !isTenantAdded()"
                variant="primary"
                title="Clone user"
              >
                <b-icon
                  v-if="isLoading"
                  icon="circle-fill"
                  animation="throb"
                  font-scale="1"
                ></b-icon>
                Clone</b-button
              >
            </b-col></b-row
          ></b-col
        >
      </b-row>
    </b-container>
   <UserWorklistSetting
     :userWorklistSettingsDialog="userWorklistSettingsDialog"
      @close="closeWorklistSettingModal"
      :userData="userWorklistsettingData"
       :selected-customer-id="selectedCustomersId"
       :is-create="true"
        @save-setting="saveUserSetting"
         @save-signature="saveSignature"
    />
     <b-modal title="Alert" :visible="isUnsavedChanges"  header-bg-variant="secondary"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
     @hidden="isUnsavedChanges=false"
    content-class="shadow" ok-title="Yes" @ok="goBack">You have unsaved changes. Are you sure you want to go back?</b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import permissions from "../common/permissions";
import userAdministrationWebServices from "../common/userAdministrationWebServices";
import UserAdministrationNavBar from "./UserAdministrationNavBar.vue";
import UserWorklistSetting from "./UserWorklistSetting.vue";
export default {
  components: {
    UserAdministrationNavBar,
    UserWorklistSetting
  },
  props: {
    fetchedUsers: Array,
    editUserInfo: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
   
  },
  data() {
    return {
       isUnsavedChanges:false,
       isFormDirty:false,
       permissionGroupSelection:{0:[]},
      selectedCustomers:{},
      userWorklistsettingData:null,
      userWorklistSettingsDialog:false,
      searchedPermission: "",
      searchedTenant: "",
      allPermissions: [],
      selectedTenants: {},
      selectedPermissions: {
        0:{}
      },
      errors: {},
      isLoading: false,
    
      items: [
        {
          id: 0,
          tenants: {},
          profile: null,
          permissions: [],
          saved: false,
          customers: {},
        },
      ],
      userInfo: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        password:"changeit"
      },

      tenantOptions: [],
      profileOptions: [],
      permissionsOptions: [],
      customerLevelPermisisons:[],
      tenantsOfCustomers:{},
      selectedCustomersId:null,
      selectedWorklistsetting:{},
      allGroupedPermissions:[],
      seletedCustomerSignature:{},
      initialFormData:{
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        password: "changeit",
      }
    };
  },
  computed: {
   fields() {
      if (this.isSuperAdmin) {
        return [
          { key: "actions", label: "Actions" },
          { key: "customer", label: "Customers" },
          { key: "tenants", label: "Tenants" },
          { key: "permissions", label: "Permissions" },
           { key: "WorklistSetting", label: "Worklist Settings" },
        ];
      } else {
        return [
          { key: "actions", label: "Actions" },
          { key: "tenants", label: "Tenants" },
          { key: "permissions", label: "Permissions" },
        ];
      }
    },
     filteredCustomers() {
      return this.tenantOptions;
    },
    filteredPermissions() {
      return this.allPermissions.filter((item) =>
        item.text.toLowerCase().includes(this.searchedPermission.toLowerCase())
      );
    },
    filteredTenants() {
      if (this.tenantOptions.length > 0) {
        return this.tenantOptions.filter((item) =>
          item.tenants.some((element) =>
            element.text
              .toLowerCase()
              .includes(this.searchedTenant.toLowerCase())
          )
        );
      } else {
        return [];
      }
    },
     isSuperAdmin() {
      return permissions.isSuperAdmin();
    },
    isMasterAdmin() {
      return permissions.isMasterAdmin();
    },
     checkUserInfoFilled() {
     for (const key in this.userInfo) {
              if (!this.userInfo[key]) {
                  return false;
              }
              if (key === 'email') {
                  if (!this.validateEmail(this.userInfo[key])) {
                      return false;
                  }
              }
      }
      return true;
    },
     isDirty() {
      for (const key in this.userInfo) {
        if (this.userInfo[key] !== this.initialFormData[key] || this.isFormDirty) {
          return true;
        }
      }
      return false;
    }
  },
  async mounted() {
   this.loadUserData()
  },
  methods: {
     goBack(){
      this.$router.push('/user_administration')
    },
    handleBack(){
      if(this.isDirty){
         this.isUnsavedChanges=true
      }else{
        this.$router.push('/user_administration')
      }
    },
     onTenantSelect(item) {
      this.$set(item, this.selectedTenants[item.id], { permissions: [] });
      this.isFormDirty=true
    },
     onPermissionSelection(e,id,group){
      if(e.length != group.permissions.length){
        if(this.permissionGroupSelection[id] &&this.permissionGroupSelection[id].includes(group.name)){
          const index = this.permissionGroupSelection[id].indexOf(group.name);
          if (index > -1) { 
            this.permissionGroupSelection[id].splice(index, 1); 
          }
          
        }
      }
      this.isFormDirty=true
    },
         onPermisisonGroupSelection(e,id,group){
      if(e.includes(group.name)){
        const selectedPermissions=[]
        group.permissions.forEach((item)=>{
          selectedPermissions.push(item.value)
        })
        this.$set(this.selectedPermissions[id],group.name,selectedPermissions)
      }else{ 
        this.$set(this.selectedPermissions[id],group.name,[])
      }
           this.$forceUpdate()
    },
        totalSelectedPermisions(id){
      const selectedPermissions = [];
         for (const roleName in this.selectedPermissions[id]) {
          for (const permission of this.selectedPermissions[id][roleName]) {
            selectedPermissions.push(permission);
           }
        }
      return selectedPermissions.length
    },
     saveUserSetting(setting){
      this.selectedWorklistsetting[this.selectedCustomersId] =setting
    },
     saveSignature(signature){
      this.seletedCustomerSignature[this.selectedCustomersId] =signature
    },
     onCustomerSelect(item, customer) {
      this.$set(item, customer.id, { permissions: [] });
      this.$set(this.selectedCustomers, item.id, customer.name);
      this.tenantsOfCustomers[item.id] = customer.tenants;
       this.selectedTenants[item.id] =[]
       this.isFormDirty=true
       this.$forceUpdate()
    },
     async loadUserData(){
      this.loadTeantsAndPermissions()
    if (this.$route.query.userId) {
      try {
        const response = await userAdministrationWebServices.fetchUserById(
          this.$route.query.userId
        );   
       const allCustomers = Object.keys(response.customers)
       const items = [];
       allCustomers.forEach((element,index)=>{
        let customerObject ={}
        customerObject[element]={permissions:[]}
       items.push({
            id: index,
            tenants: {},
            profile: null,
            permissions: [],
            saved: allCustomers.length-1==index?false:true,
            customers:customerObject
          });
          this.permissionGroupSelection[index]=[]
         this.selectedPermissions[index]={}
          const customer = response.customers[element]
       
          const selectedcustomer = this.tenantOptions.find(item=>item.id ==element)
          this.tenantsOfCustomers[index]=selectedcustomer.tenants
          this.selectedCustomers[index]=selectedcustomer.name
          const tenantsIds =Object.keys(customer.tenants)
          this.selectedTenants[index]=tenantsIds
            const customerPermisions =customer.permissions
          if(customer.tenants[tenantsIds[0]].permissions){
            
           let tenantsPermisisons =customer.tenants[tenantsIds[0]].permissions
            if(customerPermisions.length>0){
              customerPermisions.forEach((adminPermission)=>{
                  tenantsPermisisons.push(adminPermission)
              })
            }
              const  selectedPermissionWithGroups =  this.getSelectedPermisisonsWitheGroups(tenantsPermisisons)

          const selectedPermisionsGroups =selectedPermissionWithGroups.groups
          if(selectedPermisionsGroups.length>0){
            this.permissionGroupSelection[index]=selectedPermisionsGroups
          }
           const groupedPermissions =selectedPermissionWithGroups.permissions
            if(Object.keys(groupedPermissions).length>0){
           this.$set(this.selectedPermissions,index,groupedPermissions)
            }
    
          }
       })
          if (items.length > 0) {
          this.items = items;
        }
       
      } catch (error) {
        Vue.$log.error(error);
      }
    }
    },
      async loadTeantsAndPermissions() {
      try {
        const response =
          await userAdministrationWebServices.fetchAdminTenants();

        this.tenantOptions = response;
        if (this.isMasterAdmin) {
          const filteredTenants = this.tenantOptions.find((item)=>item.id ==this.$store.state.customerId)
          

          this.tenantsOfCustomers[0] = filteredTenants.tenants;
        }
      } catch (error) {
        Vue.$log.error("error::", error);
      }
       
     const groupedPermisisons =permissions.getAvailableGroupedTenantPermissions()
      this.allGroupedPermissions=groupedPermisisons
    },
      getSelectedPermisisonsWitheGroups(permissions){
                const selectedPermission = {};
              const allPermissionsPresent = [];

              for (const group of this.allGroupedPermissions) {
                const groupPermissions = [];
                for (const permission of group.permissions) {
                  if (permissions.includes(permission.value)) {
                    groupPermissions.push(permission.value);
                  }
                }
                selectedPermission[group.name] = groupPermissions;
                if(groupPermissions.length == group.permissions.length){
                   allPermissionsPresent.push(group.name);
                
                }
               
              }
          return {permissions:selectedPermission,groups:allPermissionsPresent}

    },
     formatString(str){
       return str.replace(/_/g, ' ').replace(/(\w)\w*/, (match, letter) => letter.toUpperCase() + match.slice(1));
    },
      closeWorklistSettingModal() {
      this.userWorklistSettingsDialog = false;
    },
     async userWorklistSetting(item) {
     let selectedCustomer =null
        if(this.isSuperAdmin){
        selectedCustomer= this.tenantOptions.find((element) => element.name == this.selectedCustomers[item.id]);
        }else{
          selectedCustomer= this.tenantOptions.find((element) => element.id == this.$store.state.customerId);
        } 
       if(selectedCustomer){
       try {
       this.selectedCustomersId =selectedCustomer.id
        if(Object.keys(this.selectedWorklistsetting).includes(this.selectedCustomersId)){
        
           this.userWorklistsettingData=this.selectedWorklistsetting[this.selectedCustomersId]
        }else{

        const payload ={
        customer_id:selectedCustomer.id,
        user_id:this.$route.query.userId
         }
         const response = await userAdministrationWebServices.fetchUserWorkList(payload)
         this.userWorklistsettingData=response  
        }

               
         this.userWorklistSettingsDialog = true
        } catch (error) {
          Vue.$log.error("Error while getting worklist settings",error)
        }
       }
      },
    validateEmail(email) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(email);
    },
    validateInput(field) {
    if (field === "email") {
        if (!this.validateEmail(this.userInfo.email)) {
            this.errors.email = "Enter a valid email";
        } else {
            delete this.errors.email;
        }
    } else {
        delete this.errors[field];
    }
   },
    validateUserInputField() {
      this.errors = {};
      let isValid = true;
      if (this.userInfo.username.trim() == "") {
        this.errors.username = "Username is required";
        isValid = false;
      }
      if (this.userInfo.email.trim() == "") {
        this.errors.email = "Email is required";
        isValid = false;
      } else if (!this.validateEmail(this.userInfo.email)) {
        this.errors.email = "Enter a valid email";
        isValid = false;
      }
      if (this.userInfo.firstName.trim() == "") {
        this.errors.firstName = "First name is required";
        isValid = false;
      }
      if (this.userInfo.lastName.trim() == "") {
        this.errors.lastName = "Last name is required";
        isValid = false;
      }
      if (this.userInfo.mobileNumber.trim() == "") {
        this.errors.mobileNumber = "Mobile number is required";
        isValid = false;
      }
      return isValid;
    },
    isTenantAdded() {
      if (
        this.selectedTenants[this.items[0].id] &&
        this.selectedTenants[this.items[0].id].length > 0 
      ) {
        return true;
      } else {
        return false;
      }
    },

    resetUserData() {
      this.filteredPermissionsOptions = {};
      this.filteredProfileOptions = {};
      this.userInfo = {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        mobileNumber:"",
        password:"changeit"
      };
      this.items = [
        {
          id: 0,
          tenant: null,
          profile: null,
          permissions: [],
          saved: false,
        },
      ];
    },
    onReset(item) {
      this.selectedPermissions[item.id] = [];
      this.selectedTenants[item.id] = [];
        this.$forceUpdate()
    },
     addRow(currRow) {
      if (
        this.selectedTenants[currRow.id] &&
        this.selectedTenants[currRow.id].length > 0
      ) {
       this.permissionGroupSelection[currRow.id + 1]=[]
         this.selectedPermissions[currRow.id + 1]={}
        this.items.push({
          id: currRow.id + 1,
          tenants: {},
          profile: null,
          permissions: [],
          saved: false,
          customers: {},
        });
        currRow.saved = true;
      }
      if (!this.isSuperAdmin) {
        this.tenantsOfCustomers[currRow.id + 1] =
          this.tenantsOfCustomers[currRow.id];
      }
    },
   deleteRow(currRow) {
      delete this.selectedCustomers[currRow.id]
      delete this.selectedPermissions[currRow.id];
      delete this.selectedTenants[currRow.id];
      this.items = this.items.filter((eachRow) => eachRow.id !== currRow.id);
    },
    searchProfile(item) {
      const regex = / /g;
      const tenantText = this.selectedTenant.text.replace(regex, "_");
      const tenantBasedProfiles = this.profileOptions.filter((eachProfile) => {
        if (eachProfile.text.includes(tenantText.toUpperCase())) {
          if (eachProfile.selected) {
            eachProfile.selected = false;
          }
          return eachProfile;
        }
      });
      this.filteredProfileOptions[item.id] = tenantBasedProfiles.filter(
        (eachProfile) => {
          return eachProfile.text
            .toLowerCase()
            .includes(this.profileInput.toLowerCase());
        }
      );
    },
    async cloneUser() {
     
      if (!this.validateUserInputField()) {
        return;
      }
      if (!this.isTenantAdded()) {
        this.$bvToast.toast(
          "Please add at least one tenant.",
          {
            autoHideDelay: 5000,
            solid: true,
            title: "Error",
            variant: "info",
          }
        );
        return;
      }
        try {
        this.isLoading = true;

       
        const customers = {};

        this.items.forEach((element) => {
          if (
            this.selectedTenants[element.id] &&
            this.selectedTenants[element.id].length > 0
          ) {
             let selectedPermissions = [];
                 for (const permissionSet in this.selectedPermissions[element.id]) {
                   selectedPermissions.push(...this.selectedPermissions[element.id][permissionSet]);
                 }
            // Initialize arrays for Customer and Tenant permissions
              let customerPermissions = [];
              let tenantPermisions = [];
              // Loop through selected permissions and categorize them
                selectedPermissions.forEach(permission => {
                  // Check if the permission is in the "customer" level in the original array
                  let found = false;
                  for (const group of this.allGroupedPermissions) {
                      for (const perm of group.permissions) {
                          if (perm.value === permission && perm.level === "customer") {
                              customerPermissions.push(permission);
                              found = true;
                              break;
                          }
                      }
                      if (found) {
                          break;
                      }
                  }
                  // If not in "customer" level, add to other permissions array
                  if (!found) {
                      tenantPermisions.push(permission);
                  }
              });
            let tenantsForThisCustomer = this.selectedTenants[element.id].reduce(
              (acc, tenantId) => {
                acc[tenantId] = { permissions: tenantPermisions };
                return acc;
              },
              {}
            );
                let customer =null
              if(this.isMasterAdmin){
                 const existingCustomersIds= Object.keys(customers)
                if(existingCustomersIds.includes(this.$store.state.customerId))
                {
                  tenantsForThisCustomer = {...customers[this.$store.state.customerId].tenants,...tenantsForThisCustomer}
                }
                 customer = this.tenantOptions.find(
                    (item) => item.id == this.$store.state.customerId
                  );
              }else{
                 customer = this.tenantOptions.find(
                    (item) => item.name == this.selectedCustomers[element.id]
                  );
              }
                  if (customer) {
                    customers[customer.id] = {
                      permissions: customerPermissions,
                      tenants: tenantsForThisCustomer,
                    };
                  }
            }
          });
            
                this.userInfo.customers = customers;
              
             
             const response = await userAdministrationWebServices.createUser(this.userInfo);
               await this.saveNewUserSetting(response)
              this.isLoading = false;
              this.$bvToast.toast("User cloned successfully", {
                autoHideDelay: 5000,
                solid: true,
                title: "Success",
                variant: "success",
              });
               this.selectedPermissions = { 0:{}},
              this.selectedTenants = {};
              this.userInfo = {
                username: "",
                email: "",
                firstName: "",
                lastName: "",
                mobileNumber: "",
                password:"changeit"
              };
              this.items = [
                {
                  id: 0,
                  tenants: {},
                  profile: null,
                  permissions: [],
                  saved: false,
                  customers: {},
                },
              ];
              setTimeout(() => {
                this.$router.push("/user_administration");
              }, 500);
            } catch (error) {
           
              this.isLoading = false;

              if (error?.response?.status == 409) {
                this.$bvToast.toast("User name already exist", {
                  autoHideDelay: 5000,
                  solid: true,
                  title: "Error",
                  variant: "error",
                });
              }
            }



    },
      async  saveNewUserSetting(user)
    {
      
      if(Object.keys(this.selectedWorklistsetting).length>0){
     
        for (const key in this.selectedWorklistsetting) {
           try {
            const payload ={
              user_id:user.user_id,
              customerId:key,
              setting:this.selectedWorklistsetting[key]
            }
            await userAdministrationWebServices.updateUserSetting(payload)
          
            } catch (error) {
              Vue.$log.error("Error:::;", error)
            }
        }
      }
         if(Object.keys(this.seletedCustomerSignature).length>0){
         for (const key in this.seletedCustomerSignature) {
         
           try {
            
             await userAdministrationWebServices.updateSignature(user.user_id,key,this.seletedCustomerSignature[key].file, this.seletedCustomerSignature[key].fileName)

            } catch (error) {
              Vue.$log.error("Error while saving user signature", error)
            }
        }
      }

    }
  },
};
</script>

<style>
.white-font {
  color: #fff;
}

.user-administration-dropdown .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
}
</style>
<style scoped>
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinners {
  -moz-appearance:textfield;
}
</style>